import React, { FC } from 'react'

import { isLoveMailru, isMamba, isWamba, isYonja } from 'common/constants'
import { MailRuLogoSvg } from 'components/designSystem/svgr/MailRuLogoSvg'
import { MambaLogoSvg } from 'components/designSystem/svgr/MambaLogoSvg'
import wamba from 'components/presentational/Logo/wamba.svg'
import yonja from 'components/presentational/Logo/yonja.svg'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const MainLogo: FC = () => {
  const { partnerId, partnerLogoUrl } = useShallowEqualSelector(
    ({ systemReducer: { partnerId, partner } }) => ({
      partnerId,
      partnerLogoUrl: partner?.logoUrl,
    })
  )

  if (isLoveMailru(partnerId)) {
    return <MailRuLogoSvg />
  }

  if (isMamba(partnerId)) {
    return <MambaLogoSvg />
  }

  if (isWamba(partnerId)) {
    return <img src={wamba} alt={ALT} />
  }

  if (isYonja(partnerId)) {
    return <img src={yonja} alt={ALT} />
  }

  if (partnerLogoUrl) {
    return <img src={partnerLogoUrl} alt={ALT} />
  }

  return null
}

const ALT = 'logo'
