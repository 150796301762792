import React, { ReactNode, forwardRef, useState } from 'react'

import { styled } from '@linaria/react'

import { VoidHandler } from 'common/types'
import { getWaveHeight } from 'components/page/Onboarding/functions/getWaveHeight'

import { useChangeKeyOnWidthResize } from './Wave.hooks'
import { WaveImperativeHandlers, WaveInner } from './WaveInner'

export const Wave = forwardRef<
  WaveImperativeHandlers,
  {
    step: number
    onPumpFinished?: VoidHandler
    mobile: boolean
    boxesElements?: ReactNode
  }
>(({ step, onPumpFinished, mobile, boxesElements }, ref) => {
  const [wrapperNode, setWrapperNode] = useState<HTMLDivElement | null>(null)
  const [boxesNode, setBoxesNode] = useState<HTMLDivElement | null>(null)
  const key = useChangeKeyOnWidthResize()

  return (
    <WaveWrapper ref={setWrapperNode} style={{ height: getWaveHeight(mobile) }}>
      {wrapperNode && (
        <>
          <WaveInner
            key={key} // Перемонтируем(Перерисовываем) при ресайзе
            ref={ref}
            step={step}
            wrapperNode={wrapperNode}
            boxesNode={boxesNode}
            onPumpFinished={onPumpFinished}
            mobile={mobile}
          />
          <div ref={setBoxesNode}>{boxesElements}</div>
        </>
      )}
    </WaveWrapper>
  )
})

const WaveWrapper = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  user-select: none;
`
