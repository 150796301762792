import { useEffect, useState } from 'react'

import { debounce } from 'functions/performance'

export const useChangeKeyOnWidthResize = () => {
  const [key, setKey] = useState(0)
  const [prevWidth, setPrevWidth] = useState(
    process.env.browser && window.innerWidth
  )

  useEffect(() => {
    const handleResize = debounce(() => {
      const currentWidth = window.innerWidth
      if (currentWidth !== prevWidth) {
        setKey((prevKey) => prevKey + 1)
        setPrevWidth(currentWidth)
      }
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [prevWidth])

  return key
}
