import { useEffect } from 'react'

import { preloadImages } from 'functions/preloadImages'

import { useRedesign } from './useRedesign'

/**
 * Суть в том, что SplashScreen запускает анимацию после события
 * load (https://developer.mozilla.org/en-US/docs/Web/API/Window/load_event)
 *
 * И чтобы не задерживать отображение страницы, делаем прелоад после события load
 */
export const usePreloadImagesAfterWindowLoad = (imagesPaths: string[]) => {
  const redesign = useRedesign()

  useEffect(() => {
    if (!redesign) {
      return
    }

    const handleWindowLoadEvent = () => {
      preloadImages(imagesPaths)
    }

    window.addEventListener('load', handleWindowLoadEvent)

    return () => {
      window.removeEventListener('load', handleWindowLoadEvent)
    }
  }, [imagesPaths, redesign])
}
