import { useEffect, useState } from 'react'

import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const HEIGHT = 650

export const useIsWaveVisibleOnCurrentWindowHeight = () => {
  const { ios } = useShallowEqualSelector(({ systemReducer: { ios } }) => ({
    ios,
  }))
  const [waveVisible, setWaveVisible] = useState<boolean | null>(null)

  useEffect(() => {
    const _setWaveVisible = () => setWaveVisible(window.innerHeight > HEIGHT)

    if (ios) {
      // Делаем на iOS задержку, потому что если сразу вычислять высоту,
      // то она будет неверной из-за бага в браузере:
      // https://stackoverflow.com/questions/77204872/safari-pull-to-reload-creates-different-innerheight-than-normal-reload
      window.setTimeout(_setWaveVisible, 500)
    } else {
      _setWaveVisible()
    }
  }, [ios])

  return waveVisible
}
