import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

import { styled } from '@linaria/react'

import { VoidHandler } from 'common/types'

import { SinusoidalView } from './SinusoidalView'
import { SCALE } from './Wave.constants'

export type WaveImperativeHandlers = {
  pumpWaveForward: VoidHandler
  pumpWaveBackward: VoidHandler
}

export const WaveInner = forwardRef<
  WaveImperativeHandlers,
  {
    step: number
    wrapperNode: HTMLDivElement
    boxesNode: HTMLDivElement | null
    onPumpFinished?: VoidHandler
    mobile: boolean
  }
>(({ wrapperNode, boxesNode, step, onPumpFinished, mobile }, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const sinusoidalViewRef = useRef<SinusoidalView>()

  useEffect(() => {
    if (
      wrapperNode &&
      canvasRef.current &&
      boxesNode?.children &&
      !sinusoidalViewRef.current
    ) {
      const boxes = [...boxesNode.children] as HTMLDivElement[]
      sinusoidalViewRef.current = new SinusoidalView(
        canvasRef.current,
        mobile,
        boxes,
        step,
        onPumpFinished
      )
    }
  }, [boxesNode?.children, mobile, onPumpFinished, step, wrapperNode])

  useEffect(() => {
    return () => {
      sinusoidalViewRef.current?.stopAnimating()
    }
  }, [])

  useImperativeHandle(ref, () => ({
    pumpWaveForward: () => {
      sinusoidalViewRef.current?.accendingWave()
    },
    pumpWaveBackward: () => {
      sinusoidalViewRef.current?.descendingWave()
    },
  }))

  return (
    <Canvas
      ref={canvasRef}
      width={wrapperNode.clientWidth * SCALE}
      height={wrapperNode.clientHeight * SCALE}
    />
  )
})

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`
