import React, { FC, useEffect, useRef, useState } from 'react'

import { styled } from '@linaria/react'

export const BoxesSwapper: FC<{
  value: Value | undefined
  defaultValue: string
}> = ({ value, defaultValue }) => {
  const defaultValueRef = useRef(defaultValue)
  const [{ first, second, swap }, setConfig] = useState<{
    first: Value
    second: Value
    swap: boolean
  }>({
    first: defaultValueRef.current,
    second: '',
    swap: false,
  })

  useEffect(() => {
    setConfig((config) => {
      const { first, second, swap } = config
      const _value = value || defaultValueRef.current

      return swap
        ? {
            swap: false,
            first: _value,
            second,
          }
        : {
            swap: true,
            first,
            second: _value,
          }
    })
  }, [value])

  return (
    <Inner>
      <Box1 style={{ transform: `translateY(${swap ? '100%' : 0})` }}>
        {first}
      </Box1>
      <Box2 style={{ transform: `translate(-50%, ${swap ? 0 : '100%'})` }}>
        {second}
      </Box2>
    </Inner>
  )
}

type Value = string | number

const Inner = styled.div`
  position: relative;
  overflow: hidden;
`
const Box1 = styled.div`
  width: 100%;
  transition: transform 500ms;
`
const Box2 = styled(Box1)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`
