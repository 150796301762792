import React, { FC } from 'react'

import loadable from '@loadable/component'

import gullSvg from 'components/designSystem/svg/gull.svg'
import { useIsIosVersionWithSvgAnimationIssues } from 'hooks/useIsIosVersionWithSvgAnimationIssues'

export const GullAnimation: FC = () => {
  const iosVersionWithSvgAnimationIssues = useIsIosVersionWithSvgAnimationIssues()

  if (iosVersionWithSvgAnimationIssues) {
    return (
      <div
        style={{
          position: 'relative',
          top: '12px',
          width: 160,
          height: 160,
          userSelect: 'none',
        }}
      >
        <GullAnimationLoadable />
      </div>
    )
  }

  return (
    <img
      src={gullSvg}
      width={200}
      height={200}
      draggable={false}
      style={{ userSelect: 'none' }}
    />
  )
}

const GullAnimationLoadable = loadable(() => import('./GullAnimationLottie'))
